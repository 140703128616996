/* eslint-disable no-undef */
import '../App.css';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
import Countdown from 'react-countdown';
import { fantomTestnet } from 'wagmi/chains';
import React, { useEffect, useMemo, useState } from 'react';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import logo from '../assets/logo.png';
import bone from '../assets/bone.png';
import { ethers } from 'ethers';
import check from '../assets/check.gif';
import copy from '../assets/copy.png';
import fil from '../assets/fil.png';
import ethI from '../assets/ethereum.png';
import trueI from '../assets/checked.png';
import falseI from '../assets/record.png';
import Footer from '../Components/Footer';

const renderer = ({ days, hours, minutes, seconds, completed }) => {
	/*	if (completed) {
			// Render a completed state
			return <Completionist />;
		} else {*/
	// Render a countdowns

	if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {

		window.location.reload(true);
		console.log("Mint Begins");
	}


	return <div class="counterBlock"><div class="days">{days}</div><div class="dots">:</div><div class="days">{hours}</div><div class="dots">:</div><div class="days">{minutes}</div><div class="dots">:</div><div class="sec">{seconds}</div></div>;
	/*	}*/
};

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const ops = () => {
	window.open("#");
}

const discordL = () => {
	window.open("https://discord.gg/tknBv7Xwuj");
}

//STAKING
let ABI = [
	{
		"inputs": [],
		"name": "borrow",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_xShibaContract",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Borrowed",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "calculateInterest",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "calculateRewards",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Claimed",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "claimRewards",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "depositCollateral",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Repaid",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "repay",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_collateralRate",
				"type": "uint256"
			}
		],
		"name": "setCollateralRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_dailyInterestRate",
				"type": "uint256"
			}
		],
		"name": "setDailyInterestRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rewardRatePerSecond",
				"type": "uint256"
			}
		],
		"name": "setRewardRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "stake",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Staked",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "unstake",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Unstaked",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "withdrawCollateral",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "withdrawETH",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "withdrawTokens",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "borrowedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "borrowedTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "collateralAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "collateralRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "dailyInterestRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimalNumber",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "getBorrowLimit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "getInterestTimeElapsed",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "getInterestUpToNow",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "getTimeElapsed",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "interestPaymentUpToNow",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rewardRatePerSecond",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "rewardsEarned",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "stakedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "stakedTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalRewardsClaimed",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalStaked",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "USDTtoken",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "xShiba",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

let address = "0x42011549e83F94d3efcA8a9D1e0085DBa5426766";

//MULTISIG
let ABIMultisig = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_multisigAddress",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_txIndex",
				"type": "uint256"
			}
		],
		"name": "approveProposal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "multisigAddress",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "txIndex",
				"type": "uint256"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "sender",
				"type": "address"
			}
		],
		"name": "Confirmation",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "_owners",
				"type": "address[]"
			},
			{
				"internalType": "uint256",
				"name": "_numConfirmationsRequired",
				"type": "uint256"
			}
		],
		"name": "createMultisig",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_multisigAddress",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_txIndex",
				"type": "uint256"
			}
		],
		"name": "executeProposal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "multisigAddress",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "txIndex",
				"type": "uint256"
			}
		],
		"name": "Execution",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "multisigAddress",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "txIndex",
				"type": "uint256"
			}
		],
		"name": "ExecutionFailure",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "multisigAddress",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address[]",
				"name": "owners",
				"type": "address[]"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "numConfirmationsRequired",
				"type": "uint256"
			}
		],
		"name": "MultisigCreated",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "multisigAddress",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address[]",
				"name": "newOwners",
				"type": "address[]"
			}
		],
		"name": "OwnersAdded",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_multisigAddress",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_to",
				"type": "address"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			},
			{
				"internalType": "uint256",
				"name": "_value",
				"type": "uint256"
			}
		],
		"name": "submitProposal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "multisigAddress",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "txIndex",
				"type": "uint256"
			}
		],
		"name": "TransactionSubmitted",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "approvals",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_multisigAddress",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_txIndex",
				"type": "uint256"
			}
		],
		"name": "getApprovedOwners",
		"outputs": [
			{
				"internalType": "bool[]",
				"name": "",
				"type": "bool[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getContractBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			}
		],
		"name": "getMultisigsByUser",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_user",
				"type": "address"
			}
		],
		"name": "getMultisigsFunds",
		"outputs": [
			{
				"internalType": "uint256[]",
				"name": "",
				"type": "uint256[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_multisigAddress",
				"type": "address"
			}
		],
		"name": "getOwnersOfMultisig",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_multisigAddress",
				"type": "address"
			}
		],
		"name": "getProposalsByMultisig",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint256",
						"name": "id",
						"type": "uint256"
					},
					{
						"internalType": "address",
						"name": "to",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "actor",
						"type": "address"
					},
					{
						"internalType": "bytes",
						"name": "data",
						"type": "bytes"
					},
					{
						"internalType": "uint256",
						"name": "value",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "approvals",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "executed",
						"type": "bool"
					},
					{
						"internalType": "uint256",
						"name": "requiredApprovals",
						"type": "uint256"
					}
				],
				"internalType": "struct MultiSigWallet.Transaction[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_multisigAddress",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_txIndex",
				"type": "uint256"
			}
		],
		"name": "getTxnValue",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "multisigs",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "numConfirmationsRequired",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "transactionCount",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "multisigAddress",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "funds",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "multisigsByUser",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "numConfirmationsRequired",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "transactionCount",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "multisigAddress",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "funds",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]

let addressMultisig = "0x14AaE05cd4e8A4aa05d1c3dF7C50b1Fa2013fAf9";

//TOKEN
let ABI2 = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Paused",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Unpaused",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burn",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burnFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "subtractedValue",
				"type": "uint256"
			}
		],
		"name": "decreaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "addedValue",
				"type": "uint256"
			}
		],
		"name": "increaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
];

let address2 = "0x7C28DE9eB870A227634546e36eF7CC23Fdd8f409";

const Wallet = () => {

	const { open } = useWeb3Modal()
	const { chain } = useNetwork()
	const [accountBalance, setAccountBalance] = useState(null);

	const { switchNetwork } = useSwitchNetwork()
	const clientID = "2f8f716d-b303-481b-80ef-febaf341524b";
	const [ethBalance, setEthBalance] = useState(null);
	const [walletAmount, setWalletAmount] = useState('');
	const [_multisigsByUser, setMultisigsByUser] = useState([]);
	const [_multisigs, setMultisigs] = useState();
	const [numConfirmationsRequired, setNumConfirmationsRequired] = useState();

	const [_submitProposal, set_submitProposal] = useState(0);
	const [_newMultisig, set_newMultisig] = useState(0);
	const [_quantity, set_quantity] = useState('');
	const [rConfirmations, set_Rconfirmations] = useState('');
	const [_rewardRatePerSecond, setRewardRatePerSecond] = useState('');
	const [_address, set_Address] = useState('');
	const [_to, set_To] = useState('');
	const [_value, set_Value] = useState('');
	const [_signerCost, set_signerCost] = useState('');
	const [_owners, set_Owners] = useState([]);
	const [_confirmations, set_Confirmations] = useState('');
	const [_approvals, set_approvals] = useState('');
	const [_getContractBalance, set_getContractBalance] = useState('');
	const [_getApprovedOwners, set_getApprovedOwners] = useState([]);
	const [_getMultisigsFunds, set_getMultisigsFunds] = useState([]);
	const [_funds, set_Funds] = useState(0);
	const [_getOwnersOfMultisig, set_getOwnersOfMultisig] = useState([]);
	const [_getProposalsByMultisig, set_getProposalsByMultisig] = useState([]);
	const [selectedProposal, setSelectedProposal] = useState(null);
	const [selectedAddress, setSelectedAddress] = useState(null); // state to track selected address


	const handleMaxAmount = () => {
		setEnteredAmount(walletAmount);
		setIsAmountExceeding(false); // Reset the error state
	};

	const handleAddressClick = (address) => {
		setSelectedAddress(address);
	};


	// Function to fetch required approvals
	const fetchRequiredApprovals = async (multisigAddress) => {
		try {
			// Call your smart contract function to get required approvals
			// For example:
			// const requiredApprovals = await yourContract.methods.getRequiredApprovals(multisigAddress).call();
			// setRequiredApprovals(requiredApprovals);

		} catch (error) {
			console.error('Error fetching required approvals:', error);
		}
	};

	// Handle click on a proposal row
	/*const handleProposalClick = (proposalId, multisigAddress) => {
		setSelectedProposal(proposalId);
		// Fetch required approvals when a row is clicked
		fetchRequiredApprovals(multisigAddress);
	};*/

	const handleProposalClick = async (proposalId) => {
		setSelectedProposal(proposalId);

		var data1 = await getMultisigs();
		set_Rconfirmations(data1.data[0]);
		set_Funds(data1.data[3]);
		console.log("multisigs369 : " + data1.data[3]);

		fetchMyApprovals();
		fetchGetApprovedOwners();
	};


	const { address: walletAddress } = useAccount({
		async onConnect() {
			handleConnect()
		}
	})

	const publicClient = createPublicClient({
		chain: fantomTestnet,
		transport: http()
	})

	const [statusError, setstatusError] = useState(false)
	const [statusLoading, setstatusLoading] = useState(false)
	const [success, setsuccess] = useState("")
	const [_navbarOpen, set_navbarOpen] = useState(0)
	const [_connected, setConnected] = useState(false)
	const [_receive, set_receive] = useState(0);
	const [_shibaBalance, setShibaBalance] = useState(0);
	const [_currentFetchingAddress, setCurrentFetchingAddress] = useState('0x0000000000000000000000000000000000000000');
	const [_loadProposals, setLoadProposals] = useState(false);

	async function closeNav() {
		set_navbarOpen(0);
		//this.setState({ _navbarOpen: 0 });
	}

	async function closeWindow2() {

		setstatusError(false);
		set_submitProposal(0);
		set_newMultisig(0);
	}


	async function closeWindow3() {

		set_submitProposal(0);
	}

	async function closeWindow4() {

		set_newMultisig(0);
	}

	async function navbarOpen() {
		set_navbarOpen(1);
		//this.setState({ _navbarOpen: 0 });
	}

	//........................................//
	var contract = {
		address: address,
		abi: ABI
	}

	var contract2 = {
		address: address2,
		abi: ABI2
	}

	var contract3 = {
		address: addressMultisig,
		abi: ABIMultisig
	}

	async function handleConnect() {
		if (chain.id !== 4002) {
			switchNetwork(4002)
		}

		setConnected(true);
	}

	const { refetch: getMultisigsByUser } = useContractRead({
		...contract3,
		functionName: 'getMultisigsByUser', args: [walletAddress]
	})

	const { refetch: getProposalsByMultisig } = useContractRead({
		...contract3,
		functionName: 'getProposalsByMultisig', args: [_currentFetchingAddress]
	})

	const { refetch: getMultisigs } = useContractRead({
		...contract3,
		functionName: 'multisigs', args: [/*_multisigsByUser*/_currentFetchingAddress]
	})



	const { refetch: getOwnersOfMultisig } = useContractRead({
		...contract3,
		functionName: 'getOwnersOfMultisig', args: [/*_multisigsByUser*/_currentFetchingAddress]
	})

	const { refetch: myApprovals } = useContractRead({
		...contract3,
		functionName: 'approvals', args: [_currentFetchingAddress, selectedProposal, walletAddress]
	})

	const { refetch: getApprovedOwners } = useContractRead({
		...contract3,
		functionName: 'getApprovedOwners', args: [_currentFetchingAddress, selectedProposal]
	})

	const { refetch: getMultisigsFunds } = useContractRead({
		...contract3,
		functionName: 'getMultisigsFunds', args: [walletAddress]
	})

	const { refetch: getContractBalance } = useContractRead({
		...contract3,
		functionName: 'getContractBalance'
	})

	/*const { refetch: checkOwnerApprovals } = useContractRead({
		...contract3,
		functionName: 'approvals', args: [_currentFetchingAddress, selectedProposal, selectedOwnerAddress]
	})*/

	const { refetch: getAllowance } = useContractRead({
		...contract2,
		functionName: 'allowance', args: [walletAddress, address]
	})

	const { refetch: getBalance } = useContractRead({
		...contract2,
		functionName: 'balanceOf',
		args: [walletAddress ? walletAddress : '0x']
	})

	async function fetchGetMultisigsFunds() {
		var data1 = await getMultisigsFunds();
		const getMultisigsFundsData = data1.data; // Split the addresses string
		set_getMultisigsFunds(getMultisigsFundsData); // Set the state with the array of addresses
		console.log("getMultisigsFundsData : " + getMultisigsFundsData);
	}

	async function settingAddress(address) {

		setCurrentFetchingAddress(address);
		setSelectedAddress(address);

		await new Promise(resolve => setTimeout(resolve, 500));
		console.log("settingUpAddress" + address);

		fetchOwners();
		fetchGetProposalsByMultisig();

	}

	async function fetchGetProposalsByMultisig() {
		var data1 = await getProposalsByMultisig();
		set_getProposalsByMultisig(data1.data);
		await new Promise(resolve => setTimeout(resolve, 500));
		console.log("fetched" + data1.data);
		console.log("address" + address);
	}


	/*async function fetchOwners() {
		var data1 = await getOwnersOfMultisig();

		//setMultisigs(Number(data1.data));
		set_getOwnersOfMultisig(data1.data);
		console.log("set_getOwnersOfMultisig : " + _getOwnersOfMultisig);


	}*/

	async function fetchOwners() {
		var data1 = await getOwnersOfMultisig();
		const addresses = data1.data; // Split the addresses string
		console.log("addresses : " + data1.data)
		set_getOwnersOfMultisig(addresses); // Set the state with the array of addresses
		console.log("set_getOwnersOfMultisig:", addresses);

	}

	async function fetchMyApprovals() {
		var data1 = await myApprovals();
		const myApprovalsData = data1.data; // Split the addresses string
		set_approvals(myApprovalsData); // Set the state with the array of addresses
		console.log("myApprovalsData : " + myApprovalsData);

		/*for (var x = 0; x < _getOwnersOfMultisig.length; x++) {

			checkOwnerApprovals();
		}*/
	}

	async function fetchGetApprovedOwners() {
		var data1 = await getApprovedOwners();
		const getApprovedOwnersData = data1.data; // Split the addresses string
		set_getApprovedOwners(getApprovedOwnersData); // Set the state with the array of addresses
		console.log("getApprovedOwnersData : " + getApprovedOwnersData);
	}


	/*async function fetchGetProposalsByMultisig() {
		var data1 = await getProposalsByMultisig();

		// Check if data1.data is defined and not empty
		if (data1.data && data1.data.length > 0) {
			// Access the properties of the first object in the array
			console.log("set_getProposalsByMultisig2 : " + data1.data[0].numConfirmationsRequired);
		} else {
			console.log("Data is empty or undefined");
		}

		await new Promise(resolve => setTimeout(resolve, 500));
		console.log("fetched" + data1.data);
		console.log("address" + address);
	}*/


	useEffect(() => {

		/*if (walletAddress) {
			getAccountBalance();
		}*/



		async function currentFetchingAddress() {
			if (_currentFetchingAddress == null) {
				setLoadProposals(false);
				console.log("_currentFetchingAddressFALSE :" + _currentFetchingAddress);
			} else {
				setLoadProposals(true);
				console.log("_currentFetchingAddressTRUE :" + _currentFetchingAddress.toString());

			}
		}

		/*async function fetchWalletAmount() {
			try {
				// Ensure MetaMask is available
				if (window.ethereum) {
					// Create a provider using MetaMask
					const provider = new ethers.providers.Web3Provider(window.ethereum);
					// Get the current account address
					const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
					const account = accounts[0];
					// Get the balance of the account
					const balance = await provider.getBalance(account);
					// Convert the balance from Wei to Ether
					const etherBalance = ethers.utils.formatEther(balance);
					// Update the wallet amount state
					setWalletAmount(etherBalance);
				} else {
					console.log('MetaMask not available');
				}
			} catch (error) {
				console.error('Error fetching wallet amount:', error);
			}
		}*/

		async function fetchShiba() {
			var data3 = await getBalance();

			setShibaBalance(Number(data3.data) / 10 ** 9);
		}

		async function fetchMultisigs() {
			var data1 = await getMultisigs();

			//setMultisigs(Number(data1.data));
			setMultisigsByUser(data1.data);
			console.log("_multisigsByUser : " + _multisigsByUser[0]);
		}

		async function fetchGetContractBalance() {
			var data1 = await getContractBalance();
			set_getContractBalance(data1.data);
		}


		set_receive((_quantity * _rewardRatePerSecond * 86400));

		if (_connected) {
			currentFetchingAddress();
			fetchGetProposalsByMultisig();
			fetchShiba();
			//fetchWalletAmount();
			fetchGetMultisigsFunds();
			fetchGetContractBalance();
			//fetchMultisigs();
		}

		// eslint-disable-next-line no-use-before-define
	}, [walletAddress, _connected, _quantity, numConfirmationsRequired, _getMultisigsFunds
		  /*getRewardRatePerSecond, _rewardRatePerSecond, _receive*/]);

	/*const { data, refetch, isSuccess } = useContractReads({
		contracts: [
			{ ...contract, functionName: 'totalSupply', args: [0] },
			{ ...contract, functionName: 'max_per_wallet' },
			{ ...contract, functionName: 'publicSaleCost', args: [0] },
			//{ ...contract, functionName: 'public_mint_status' }

		]
	},)*/

	/*useMemo(() => {

		if (isSuccess === true) {
			console.log("isSuccess : " + isSuccess);
			//settotalSupply(Number(data[0].result));
			settotalSupply(Number(data[0].result));
			console.log("settotalSupply : " + settotalSupply);
			//set_max_per_wallet(Number(data[1].result));
			set_max_per_wallet(Number(data[1].result));
			console.log("set_max_per_wallet : " + set_max_per_wallet);
			//set_costGateway(Number(data[4].result) / 10 ** 4); // Convert WEI to ETH
			set_costGateway(Number(data[4].result) / 10 ** 4); // Convert WEI to ETH
			console.log("set_costGateway : " + set_costGateway);
			//set_public_mint_status(data[5].result);
		}
	}, [_totalSupply, data, isSuccess])*/

	/*async function getAccountBalance() {
		try {
			// Create a new Web3Provider using window.ethereum
			let provider = new ethers.providers.Web3Provider(window.ethereum);

			// Fetch account balance
			let balance = await provider.getBalance(walletAddress);

			// Format balance from Wei to Ether
			balance = ethers.utils.formatEther(balance);

			// Set account balance in state
			setAccountBalance(balance.substring(0, 4));
		} catch (error) {
			console.error('Error fetching account balance:', error);
		}
	}*/

	const { writeAsync } = useContractWrite({
		//...contract,
		//functionName: 'mint',
		onError(error) {
			if (error.message.includes('balance')) {
				setstatusError(true)
				setstatusLoading(false)
			}
		}
	})

	//........................................//

	async function submitProposalOpen() {
		set_submitProposal(1);
	}

	async function newMultisigOpen() {
		set_newMultisig(1);
	}

	async function submitProposal() {

		try {
			setstatusLoading(true);
			var res = await writeAsync({
				...contract3,
				functionName: 'submitProposal',
				args: [_address, _to, '', Number(_value * 10 ** 18)],
				//value: '100000000000000000',
				gasLimit: '685000'
			})

			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 5000));
				window.location.reload(true);
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (err) {
			setsuccess(false)
			setstatusError(true)
			setstatusLoading(false)
			console.log(err);
		}

	}

	async function approveProposal() {

		try {
			setstatusLoading(true);
			var res = await writeAsync({
				...contract3,
				functionName: 'approveProposal',
				args: [_currentFetchingAddress, selectedProposal],
				//value: '100000000000000000',
				gasLimit: '685000'
			})

			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 5000));
				window.location.reload(true);
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (err) {
			setsuccess(false)
			setstatusError(true)
			setstatusLoading(false)
			console.log(err);
		}

	}

	async function executeProposal() {

		try {
			setstatusLoading(true);
			var res = await writeAsync({
				...contract3,
				functionName: 'executeProposal',
				args: [_currentFetchingAddress, Number(selectedProposal)],
				//value: '100000000000000000',
				gasLimit: '685000'
			})

			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 5000));
				window.location.reload(true);
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (err) {
			setsuccess(false)
			setstatusError(true)
			setstatusLoading(false)
			console.log(err);
		}

	}

	async function createMultisig() {
		try {
			setstatusLoading(true);

			// Convert the comma-separated string of addresses to an array
			const ownersArray = _owners.split(',').map(address => address.trim());

			var res = await writeAsync({
				...contract3,
				functionName: 'createMultisig',
				args: [ownersArray, _confirmations], // Pass ownersArray instead of _owners
				gasLimit: '685000',
				value: parseEther(_signerCost)
			})

			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 5000));
				window.location.reload(true);
			} else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (err) {
			setsuccess(false)
			setstatusError(true)
			setstatusLoading(false)
			console.log(err);
		}
	}

	const isExceedingStakedAmount = () => {
		const enteredAmount = Number(_quantity);
		console.log("entered Amount : " + enteredAmount);
		console.log("_quantity Amount : " + _stakedAmount);
		return enteredAmount * 10 ** 18 > _stakedAmount;
	};

	function shortenAddress(address) {
		return address.slice(0, 6) + "..." + address.slice(-4);
	}

	function shortenAddress2(address) {
		return address.slice(0, 30) + "..." + address.slice(-4);
	}

	function generateScanUrl(address) {
		return `https://testnet.ftmscan.com/address/${address}`;
	}

	return (
		<>
			<div class="allWrap2">
				<div class="light">

					<div class="headers">

						<div class="h1">
							<div class="logoDiv">
								<img onClick={() => window.location.href = '/'} src={logo} />

								<div class="connect">
									<div onClick={() => window.location.href = 'stake'} id="fontSize">Stake</div>
									<div onClick={() => window.location.href = 'multisig'} id="fontSize" >Multisig</div>
									<div onClick={() => window.location.href = 'wallet'} id="fontSize" ><span className='activeNav'>Wallet</span></div>
									<div onClick={() => window.location.href = 'faq'} id="fontSize" >FAQ</div>
								</div>

							</div>

							<div className='web3'><Web3Button /></div>

						</div>

					</div>

					{_navbarOpen < 1 ?
						(<div class="miniBarMain">
							<div class="logoDiv"><img src={logo} /></div>

							<div className='web3'><Web3Button /></div>

							<div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
						</div>) :
						(<div class="miniBarMain">
							<div class="logoDiv"><img src={logo} /></div>

							<div className='web3'><Web3Button /></div>

							<div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
						</div>)}

					{_navbarOpen > 0 ?
						(<div class="littleNav">
							<div onClick={() => window.location.href = 'stake'} id="fontSize">Stake</div>
							<div onClick={() => window.location.href = 'multisig'} id="fontSize" >Multisig</div>
							<div onClick={() => window.location.href = 'wallet'} id="fontSize" ><span className='activeNav'>Wallet</span></div>
							<div onClick={() => window.location.href = 'faq'} id="fontSize" >FAQ</div>
						</div>) : null}


					<div className='multisigMain-1'>
						<div className='multisigMain'>
							<div className='section1M'>
								<div className='multisigMain2'>

									<div className='m1'>Accounts</div>

									{walletAddress === undefined ?
										<div>
											<div className='m2'>Connect your wallet to access your accounts</div>
											<button className='buttonM' onClick={open}>Connect Wallet</button>
										</div>
										:

										<div className='wallet'>
											<img className='icon2' src={ethI} />
											<div className='walletAdd'>0x100f ... 4FfF05</div></div>
									}

								</div>
							</div>

							<div className='section2M'>
								<Element name="addresses">

									<div className='box2M'>
										{walletAddress === undefined ?
											<div className='signerSection'>
												<div><span className='multisigH'>Disconnected</span></div>

												<div>{Number(_getContractBalance) / 10 ** 18} FIL</div>
											</div> :
											<div>
												<div className='signerSection'>
													<div><span className='multisigH'>Connected</span></div>

													<div className='flexBtns'>
														<div><button onClick={approveProposal}>Deposit to Pool</button></div>
														<div><button onClick={approveProposal}>Send FIL</button></div>
													</div>
												</div>

												<div className='iconsAddressMain'>
													<div className='iconsAddress'>
														<img className='icon' src={fil} />
														<div>fd114df...254adx  <img className='copyImg' src={copy} /></div>
													</div>

													<div className='iconsAddress'>
														<img className='icon' src={ethI} />
														<div>0x114df...254adx <img className='copyImg' src={copy} /></div>
													</div>
												</div>
											</div>
										}

										<div className='amountDetailsMain'>
											<div className='amountDetails'>
												<div className='balanceD'>Balance</div>
												<div className='amountD'>0 FIL</div>
											</div>

											<div className='amountDetails'>
												<div className='balanceD'>iFIL balance</div>
												<div className='amountD'>0 iFIL</div>
											</div>
										</div>

										<div className='smallH'>Tokens</div>

										<div className='amountDetails2'>
											<div className='signerSection'>
												<div><span className='filAmount'>iFIL: 0 iFIL</span></div>

												<div className='flexBtns'>
													<div><button className='smallBtns' onClick={approveProposal}>Transfer</button></div>
												</div>
											</div>

										</div>

										<p></p>

										<div className='amountDetails2'>
											<div className='signerSection'>
												<div><span className='filAmount'>WFIL: 0 WFIL</span></div>

												<div className='flexBtns'>
													<div><button className='smallBtns' onClick={approveProposal}>Wrap</button></div>
													<div><button className='smallBtns' onClick={approveProposal}>Unwrap</button></div>
													<div><button className='smallBtns' onClick={approveProposal}>Transfer</button></div>
												</div>
											</div>

										</div>
									</div>
								</Element>

								<div className='box2M'>
									<div className='signerSection'>
										<div><span className='multisigH'>Transactions</span></div>
									</div>

									<div class="table-container">

										<table>
											<thead>
												<tr>
													<th>DATE</th>
													<th>CID</th>
													<th>FROM</th>
													<th>TO</th>
													<th>METHOD</th>
													<th>AMOUNT</th>
												</tr>
											</thead>

											{

												_loadProposals ?
													<tbody>
														{_getProposalsByMultisig.map((_proposalsByMultisig, index) => (
															<tr key={index} onClick={() => handleProposalClick(_proposalsByMultisig.id)} className={selectedProposal === _proposalsByMultisig.id ? 'selected' : ''}>
																<td>{Number(_proposalsByMultisig.id)}</td>
																<td><a href={generateScanUrl(address)} target="_blank">{shortenAddress(_proposalsByMultisig.to)}</a></td>
																<td><a href={generateScanUrl(address)} target="_blank">{shortenAddress(_proposalsByMultisig.actor)}</a></td>
																<td>{Number(_proposalsByMultisig.value) / 10 ** 18}</td>
																<td>{Number(_proposalsByMultisig.approvals) >= Number(_proposalsByMultisig.requiredApprovals) ? <div className='approvediv'><span className='Approved'>Approved</span>{!_proposalsByMultisig.executed ? <button onClick={executeProposal} className='ApprovedBtn'>Execute</button> : null}</div> : 'Pending'}</td>
																<td>{_proposalsByMultisig.executed ? 'Executed' : 'Pending'}</td>
															</tr>
														))}
													</tbody> : null}
										</table>

									</div>
								</div>

							</div>

						</div>

						<img className='bone' src={bone} />

					</div>

				</div>

				<Footer />
			</div>

			{_newMultisig > 0 ?
				<div class="formMain2-2">
					<form onClick={closeWindow4}>
						<button class="closeNote">✖</button>
					</form>
					<div class="formMain-2-2">

						<div class="input-container-Main">

							<p className='inP1'>Create Multisig</p>
							<p className='inP2'>Please enter the details of your multisig creation</p>

							<div class="input-container">
								<div>Owners:</div>
								<input type="text" id="multisigAddress" name="multisigAddress" placeholder="0xcd...bc4, 0xef...2fd, 0xfc...d4s"
									value={_owners}
									onChange={event => set_Owners(event.target.value)}
								/>
							</div>

							<div class="input-container">
								<div>Confirmations required:</div>
								<input id="to" type='number' name="to" placeholder="Enter required confirmations..."
									value={_confirmations}
									onChange={event => set_Confirmations(event.target.value)}
								/>
							</div>

							<div class="input-container">
								<div>Funds:</div>
								<div className='filAndAmount'>
									<input id="to" type='number' name="to" placeholder="Enter the transferring amount..."
										value={_signerCost}
										onChange={event => set_signerCost(event.target.value)}
									/>
									<div className='filB'>FIL</div>
								</div>
							</div>

							<button className='buttonM' onClick={createMultisig}>Submit</button>
						</div>
					</div>
				</div> : null}

			{_submitProposal > 0 ?
				<div class="formMain2-2">
					<form onClick={closeWindow3}>
						<button class="closeNote">✖</button>
					</form>
					<div class="formMain-2-2">

						<div class="input-container-Main">

							<p className='inP1'>Send Details</p>
							<p className='inP2'>Please enter the details of your transaction below</p>

							<div class="input-container">
								<div>Multisig Address:</div>
								<input type="text" id="multisigAddress" name="multisigAddress" placeholder="Enter multisig address..."
									value={_address}
									onChange={event => set_Address(event.target.value)}
								/>
							</div>

							<div class="input-container">
								<div>To:</div>
								<input type="text" id="to" name="to" placeholder="Enter recipient address..."
									value={_to}
									onChange={event => set_To(event.target.value)}
								/>
							</div>

							<div class="input-container">
								<div>Value:</div>
								<div className='filAndAmount'>
									<input id="value" type='number' name="value" placeholder="Enter value..."
										value={_value}
										onChange={event => set_Value(event.target.value)}
									/>
									<div className='filB'>FIL</div>
								</div>
							</div>
							<button className='buttonM' onClick={submitProposal}>Submit</button>
						</div>
					</div>
				</div> : null}


			<div>

				{statusError ?
					(<div class="formMain2">
						<form onClick={closeWindow2}>
							<button class="closeNote">✖</button>
						</form>
						<div class="formMain-2">

							<div>
								<div class="errorMessage">
									Sorry, something went wrong please try again later
								</div>
							</div>
						</div>

					</div>) : null}

				{statusLoading ?
					(<div class="formMain2">
						<div class="formMain-2">

							<div class="loader"></div>

						</div>

					</div>) : null}

				{success ?
					(<div class="formMain2">
						<div class="formMain-2">

							<div>
								<img class="check" src={check} />

								<div class="success-message">
									<h2>Transaction Successful!</h2>
								</div>
							</div>
						</div>


					</div>) : null}

			</div>

		</>
	);

}
export default Wallet;
