import React, { useState, useEffect } from 'react';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
import '../App.css';
//import { contractStaking } from '../config';
import { ethers, utils } from 'ethers';
import { Route, Routes } from 'react-router-dom';
import tg from '../assets/telegram2.png';
import mail from '../assets/email2.png';
import twitter from '../assets/twitter2.png';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import logo from '../assets/logo.png';
import shib from '../assets/shib.png';
import faq from '../assets/faq (2).png';
import Footer from '../Components/Footer';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

let ABI = [
    {
        "inputs": [],
        "name": "borrow",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_xShibaContract",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "Borrowed",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "calculateInterest",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "calculateRewards",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "Claimed",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "claimRewards",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "depositCollateral",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "Repaid",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "repay",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_collateralRate",
                "type": "uint256"
            }
        ],
        "name": "setCollateralRate",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_dailyInterestRate",
                "type": "uint256"
            }
        ],
        "name": "setDailyInterestRate",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_rewardRatePerSecond",
                "type": "uint256"
            }
        ],
        "name": "setRewardRate",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "stake",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "Staked",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "unstake",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "Unstaked",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "withdrawCollateral",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            }
        ],
        "name": "withdrawETH",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_token",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            }
        ],
        "name": "withdrawTokens",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "borrowedAmount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "borrowedTime",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "collateralAmount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "collateralRate",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "dailyInterestRate",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "decimalNumber",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getBorrowLimit",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getContractBalance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getInterestTimeElapsed",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getInterestUpToNow",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getTimeElapsed",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "interestPaymentUpToNow",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "rewardRatePerSecond",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "rewardsEarned",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "stakedAmount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "stakedTime",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalRewardsClaimed",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalStaked",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "USDTtoken",
        "outputs": [
            {
                "internalType": "contract IERC20",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "xShiba",
        "outputs": [
            {
                "internalType": "contract IERC20",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];

let address = "0xA71A19D2ad216f1152832AE563eb6a9e397893C1";

const Home = () => {
    const [_navbarOpen, set_navbarOpen] = useState(0)
    const [_getContractBalance, set_getContractBalance] = useState(0);
    const [_totalRewardsClaimed, set_totalRewardsClaimed] = useState(0);

    var contract = {
        address: address,
        abi: ABI
    }

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }

    const { refetch: getContractBalance } = useContractRead({
        ...contract,
        functionName: 'getContractBalance'
    })

    const { refetch: getTotalRewardsClaimed } = useContractRead({
        ...contract,
        functionName: 'totalRewardsClaimed'
    })


    useEffect(() => {

        async function fetchGetContractBalance() {
            var data1 = await getContractBalance();
            set_getContractBalance(data1.data);
        }

        async function fetchTotalRewardsClaimed() {
            var data1 = await getTotalRewardsClaimed();
            set_totalRewardsClaimed(data1.data);
        }

        const animateNumbers = () => {
            const numberElements = document.querySelectorAll('.number');
            numberElements.forEach((element) => {
                const targetNumber = parseFloat(element.getAttribute('data-count').replace(/[^0-9.]+/g, ''));
                const isPercentage = element.textContent.includes('%');
                const duration = 2500;

                let startTime;
                function step(timestamp) {
                    if (!startTime) startTime = timestamp;
                    const progress = timestamp - startTime;
                    const currentNumber = (progress / duration) * targetNumber;

                    const formattedNumber = isPercentage
                        ? currentNumber.toFixed(3) + '%'
                        : currentNumber.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '+';

                    element.textContent = formattedNumber;

                    if (progress < duration) {
                        requestAnimationFrame(step);
                    } else {
                        element.textContent = isPercentage
                            ? targetNumber.toFixed(3) + '%'
                            : targetNumber.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '+';
                    }
                }

                requestAnimationFrame(step);
            });
        };

        animateNumbers();
        fetchGetContractBalance();
        fetchTotalRewardsClaimed();
    }, []);


    return (
        <>
            <div class="allWrap">
                <div class="light">

                    <div class="cont">

                        <div class="headers">

                            <div class="h1">
                                <div class="logoDiv">
                                    <img onClick={() => window.location.href = '/'} src={logo} />

                                    <div class="connect">
                                        <div onClick={() => window.location.href = 'stake'} id="fontSize">Stake</div>
                                        <div onClick={() => window.location.href = 'multisig'} id="fontSize" >Multisig</div>
                                        <div onClick={() => window.location.href = 'faq'} id="fontSize" >FAQ</div>
                                    </div>

                                </div>

                                <button class="connectBtn">Connect Wallet</button>

                            </div>

                        </div>
                        {_navbarOpen < 1 ?
                            (<div class="miniBarMain">
                                <div class="logoDiv"><img src={logo} /></div>

                                <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                            </div>) :
                            (<div class="miniBarMain">
                                <div class="logoDiv"><img src={logo} /></div>

                                <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                            </div>)}

                        {_navbarOpen > 0 ?
                            (<div class="littleNav">
                                <div onClick={() => window.location.href = 'stake'} id="fontSize">Stake</div>
                                <div onClick={() => window.location.href = 'multisig'} id="fontSize" >Multisig</div>
                                <div onClick={() => window.location.href = 'faq'} id="fontSize" >FAQ</div>

                            </div>) : null}

                        <Element name="home">
                            <div class="introductionMain">

                                <div class="introduction">
                                    <div class="in1">WELCOME TO SHIBADEX</div>
                                    <div class="in2">Swap, Stake & Earn Rewards</div>

                                    <div class="amount">
                                        <div class='amount2Div'>
                                            <div class="number" data-count="18.25 %">18.25%</div>
                                            <div className='nameNum'>APY</div>
                                        </div>

                                        <div class='amount2Div'>
                                            <div class="number" data-count="500">500</div>
                                            <div className='nameNum'>Multisigs</div>
                                        </div>

                                        <div class='amount2Div'>
                                            <div class="number" data-count="1000">1000</div>
                                            <div className='nameNum'>Stakers</div>
                                        </div>
                                    </div>

                                    <button class="connectBtn2" onClick={() => window.location.href = 'stake'}>STAKE & EARN</button>

                                </div>

                            </div>
                        </Element>

                        <img className='faqI' src={faq} />
                        <img className='shib' src={shib} />

                    </div>

                </div>
                <Footer />
            </div>

        </>
    );
};

export default Home;
